import logo from './logo.svg';
import './App.css';
import MenuBar from './MenuBar'
import { useEffect } from 'react';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
function App() {
  const [apiData,setJsonData]=useState(null);
  const [ambient,setAmbient]=useState([]);
  const [motTmp,setMottmp]=useState([]);
  const [tmpDiff,setTmpDiff]=useState([]);
  const [soundBins,setSoundBins]=useState([]);
  const [rChannel,setRCH]=useState([]);
  const [bChannel,setBCH]=useState([]);
  const [yChannel,setYCH]=useState([]);
  const [xAxis,setX]=useState([]);
  const [yAxis,setY]=useState([]);
  const [zAxis,setZ]=useState([]);
  const [totalData,setTotData]=useState(0);
  const [lastUpdated,lastUpdatedOn]=useState('');
  const [maxTmpAmb,setMaxTmpAmb]=useState(0);
  const [maxTmpMotor,setMaxMotorTmp]=useState(0);
  const [maxTmpDiff,setMaxTmpDiff]=useState(0);
  const [maxAcoustic,setAcoustic]=useState(0);
  const [maxXaxisBin,setMaxXAxisBin]=useState(0);
  const [maxYaxisBin,setMaxYAxisBin]=useState(0);
  const [maxZaxisBin,setMaxZAxisBin]=useState(0);

  // Function to fetch JSON data
  const apiUrl = 'https://core-technologies.com/demo/railwayHealth/getdata.php';
  const fetchData = async () => {
    try {
        const response = await fetch(apiUrl);
        const tdata = await response.json();
        setTotData(tdata.length);
        const maxAmb = tdata.reduce((acc, obj) => Math.max(acc, obj.ambient_temp), -Infinity);
        const maxMot = tdata.reduce((acc, obj) => Math.max(acc, obj.motor_temp), -Infinity);
        const maxAcous=getMaxSoundBinsValue(tdata);
        setAcoustic(maxAcous);
        const maxDifference = tdata.reduce((max, obj) => {
          const difference = obj.motor_temp - obj.ambient_temp;
          return Math.max(max, difference);
        }, -Infinity);
        setMaxTmpAmb(maxAmb);
        setMaxMotorTmp(maxMot);
        setMaxTmpDiff(maxDifference);

        const maxX=getMaxBinValue(tdata,"x_axis_bins");
        const maxY=getMaxBinValue(tdata,"y_axis_bins");
        const maxZ=getMaxBinValue(tdata,"z_axis_bins");
        setMaxXAxisBin(maxX);
        setMaxYAxisBin(maxY);
        setMaxZAxisBin(maxZ);
        const data=tdata.slice(-10);
        setJsonData(data);
        const ambientVal=data.map(item=>item.ambient_temp);
        console.log(data);
        setAmbient(ambientVal);

        const moto=data.map(data=>data.motor_temp);
        setMottmp(moto);

        const lastTimeStamp=data[data.length-1].timestamp;
        lastUpdatedOn(lastTimeStamp);

        const diff=moto.map((value,index)=>(value-ambientVal[index])+50);
        setTmpDiff(diff);

        let soundBin=Array(512).fill(-Infinity);
        data.forEach(item=>{
          item.sound_bins.forEach((value,index)=>{
            if(value>soundBin[index]){
              soundBin[index]=value;
            }
          })
        })
        setSoundBins(soundBin.reverse());

        const rc=data.map(item=>item.r_channel_current);
        setRCH(rc);

        const yc=data.map(item=>item.y_channel_current);
        setYCH(yc);

        const bc=data.map(item=>item.b_channel_current);
        setBCH(bc);

        let maxXAxisBins = Array(128).fill(-Infinity);
        let maxYAxisBins = Array(128).fill(-Infinity);
        let maxZAxisBins = Array(128).fill(-Infinity);

        // Loop through each object in the array
        data.forEach(item => {
            // Loop through each bin value in the current object for x axis
            item.x_axis_bins.forEach((value, index) => {
                // Update the corresponding element in maxXAxisBins if the current value is greater
                if (value > maxXAxisBins[index]) {
                    maxXAxisBins[index] = value;
                }
            });
            
            // Repeat the same process for y axis bins
            item.y_axis_bins.forEach((value, index) => {
                if (value > maxYAxisBins[index]) {
                    maxYAxisBins[index] = value;
                }
            });

            // Repeat the same process for z axis bins
            item.z_axis_bins.forEach((value, index) => {
                if (value > maxZAxisBins[index]) {
                    maxZAxisBins[index] = value;
                }
            });
        });

        setX(maxXAxisBins);
        setY(maxYAxisBins);
        setZ(maxZAxisBins);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
function classifyData(){
  if(apiData!==null){
   
  }
}
const getMaxBinValue = (dataArray, binName) => {
  let maxBinValue = Number.NEGATIVE_INFINITY;

  // Iterate through each object in the array
  dataArray.forEach((obj) => {
      // Get the bin array from the current object
      const binArray = obj[binName];

      // Find the maximum value in the bin array
      const maxBinArrayValue = Math.max(...binArray);

      // Update the maximum value if the current array value is greater
      if (maxBinArrayValue > maxBinValue) {
          maxBinValue = maxBinArrayValue;
      }
  });

  return maxBinValue;
};
const getMaxSoundBinsValue = (dataArray) => {
  let maxSoundBinsValue = Number.NEGATIVE_INFINITY;

  // Iterate through each object in the array
  dataArray.forEach((obj) => {
      // Get the sound_bins array from the current object
      const soundBinsArray = obj.sound_bins;

      // Find the maximum value in the sound_bins array
      const maxSoundBinsArrayValue = Math.max(...soundBinsArray);

      // Update the maximum value if the current array value is greater
      if (maxSoundBinsArrayValue > maxSoundBinsValue) {
          maxSoundBinsValue = maxSoundBinsArrayValue;
      }
  });

  return maxSoundBinsValue;
};
// useEffect hook to fetch data when the component mounts
useEffect(() => {
    fetchData();
    console.log("Working")
    classifyData();
       // Set up an interval to call fetchData every 60 seconds
       const intervalId = setInterval(fetchData, 60000);

       // Clean up function to clear the interval when the component unmounts or when dependencies change
       return () => clearInterval(intervalId);

}, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount
 // Chart data and options
const soundfft={
  labels:Array.from(soundBins),
  datasets: [
      {
          label: 'Soundbins',
          data: soundBins, // Use fetched ambient temperature data here
          fill: true,
          borderColor: 'rgb(75, 0, 130)',
          
          tension: 0.5,
          pointRadius:1
      },
     
  ],
};
const motTmpChartData = {
  labels:Array.from(ambient),
  datasets: [
      {
          label: 'Ambient',
          data: motTmp, // Use fetched ambient temperature data here
          fill: false,
          borderColor: 'rgb(255, 100, 12)',
          
          tension: 0.5,
          pointRadius:1
      },
      {
        label: 'Motor',
        data: ambient, // Use fetched ambient temperature data here
        fill: false,
        borderColor: 'rgb(75, 222, 192)',
        tension: 0.4,
        pointRadius:1
    },
  
  ],
};
const vibrationX={
  labels:Array.from(xAxis),
  datasets: [
      {
          label: 'X',
          data: xAxis, // Use fetched ambient temperature data here
          fill: false,
          borderColor: 'rgb(75, 0, 130)',
          tension: 0.5,
          pointRadius:1
      },
  ],
}
const vibrationY={
  labels:Array.from(yAxis),
  datasets: [
      {
          label: 'Y',
          data: yAxis, // Use fetched ambient temperature data here
          fill: false,
          borderColor: 'rgb(75, 0, 130)',
          tension: 0.5,
          pointRadius:1
      },
  ],
}
const vibrationZ={
  labels:Array.from(zAxis),
  datasets: [
      {
          label: 'Z',
          data: zAxis, // Use fetched ambient temperature data here
          fill: false,
          borderColor: 'rgb(75, 0, 130)',
          tension: 0.5,
          pointRadius:1
      },
  ],
}
const channelCurrent={
  labels:Array.from(rChannel),
  datasets: [
      {
          label: 'R',
          data: rChannel, // Use fetched ambient temperature data here
          fill: false,
          borderColor: 'rgb(255, 0, 12)',
          
          tension: 0.5,
          pointRadius:1
      },
      {
        label: 'Y',
        data: yChannel, // Use fetched ambient temperature data here
        fill: false,
        borderColor: 'rgb(255, 222, 0)',
        tension: 0.4,
        pointRadius:1
    },
    {
      label: 'B',
      data: bChannel, // Use fetched ambient temperature data here
      fill: false,
      borderColor: 'rgb(0, 0, 254)',
      tension: 0.4,
      pointRadius:1
  },
  ],
}
const chartOptions = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false,
     },
     legend:{
      display:false
     },
     tooltip:{
      callbacks:{
        title:()=>null,
        label: function(context) {
          var label = context.dataset.label || '';
          if (context.datasetIndex === 0 || context.datasetIndex === 1) {
            label += ': ' + context.parsed.y.toFixed(2);
          }
          return label;
        }
      }
     }
    
  },
  scales: {
      x: {
         display: false // Hide x-axis labels
      },
      y: {
          title: {
              display: false,
              text: 'Temp(°C)',
          },
          max:100,
          min:0
      },
  },
};
const chartVib= {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: false,
     },
     legend:{
      display:false
     },
     tooltip:{
      callbacks:{
        title:()=>null,
        label: function(context) {
          var label = context.dataset.label || '';
          if (context.datasetIndex === 0 || context.datasetIndex === 1) {
            label += ': ' + context.parsed.y.toFixed(2);
          }
          return label;
        }
      }
     }
    
  },
  scales: {
      x: {
         display: false // Hide x-axis labels
      },
      y: {
          title: {
              display: false,
              text: 'Temp(°C)',
          },
         
      },
  },
};

const msg=['Total Dataset: '+totalData,'Max temp: (amb:'+maxTmpAmb.toFixed(2)+"),(Motor:"+maxTmpMotor.toFixed(2)+"),(Difference:"+maxTmpDiff.toFixed(2)+")",
"Max Acoustic:"+maxAcoustic.toFixed(2),
"Max Vibration: (X: "+maxXaxisBin+",Y: "+maxYaxisBin+",Z: "+maxZaxisBin+")",
"Based On Predictive Motor Fault Analysis AI Model the Motor Looks OK"
]

  return (
    <div className="App">
      <MenuBar/>
      <div style={{flexDirection:'column',flex:1,height:80,justifyContent:'center'}}>
      <div className='contentFlx'>
        <div className='innerFlx'>
          <div className='divHead'>Overall Performance</div>
          <div>
              <div style={{fontFamily:'monospace',margin:2}}>Last Data Updated on : {lastUpdated}</div>
              {msg.map((item,index)=>{
                return(<div key={index}>{item}</div>)
              })}
          </div>
        </div>

          
        
         
        </div>
        <div className='contentFlx'>
          <div className='innerFlx'>
            <div className='divHead'>Temparature</div>
            ΔT(Max)={Math.max(...tmpDiff).toFixed(2)}°c [OK]
            {tmpDiff.length>0?(
              <Line style={{height:'250px'}} className='center' data={motTmpChartData} options={chartOptions} />
            ):(<div>No Data</div>)}
         
          </div>
          <div className='innerFlx'>
            <div className='divHead'>Current (R,B,Y)</div>
            Max (R= {Math.max(...rChannel).toFixed(2)}, B= {Math.max(...bChannel).toFixed(2)}, Y= {Math.max(...yChannel).toFixed(2)} ) [Ok]
            <Line
           
           className='center'
             data={channelCurrent}
             options={{
               responsive: true,
               interaction: {
                 mode: 'index',
                 intersect: false,
               },
               plugins: {
                 title: {
                   display: false,
                  },
                  legend:{
                   display:false
                  },
                  tooltip:{
                   callbacks:{
                     title:()=>null
                   }
                  }
                 },
                 scales:{
                   x:{display:false},
                  y:{
                   max:100,
                   min:0
                  }
                 }
             }}
             />
             
          </div>
         
          <div className='innerFlx'>
            <div className='divHead'>Acoustics</div>
            <span>Δ(Max)={Math.max(...soundBins).toFixed(2)} [OK]</span>
            <Line
           
            className='center'
              data={soundfft}
              options={{
                responsive: true,
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
                plugins: {
                  title: {
                    display: false,
                   },
                   legend:{
                    display:false
                   },
                   tooltip:{
                    callbacks:{
                      title:()=>null
                    }
                   }
                  },
                  scales:{
                    x:{display:false},
                  
                  }
              }}
              />
              
          </div>
          
         
        </div>
        <div className='contentFlx'>
              <div className='innerFlx'>
                <div className='divHead'>Vibration X</div>
                Max {Math.max(...xAxis).toFixed(2)} [OK]
                  <Line style={{height:'250px'}} className='center' data={vibrationX} options={chartVib} />
                  
              </div>
              <div className='innerFlx'>
                <div className='divHead'>Vibration Y</div>
                Max {Math.max(...yAxis).toFixed(2)} [OK]
                <Line style={{height:'250px'}} className='center' data={vibrationY} options={chartVib} />
              </div>
              <div className='innerFlx'>
                <div className='divHead'>Vibration Z</div>
                Max {Math.max(...zAxis).toFixed(2)} [OK]
                <Line style={{height:'250px'}} className='center' data={vibrationZ} options={chartVib} />
              </div>
        </div>
       
      </div>
    </div>
  );
}

export default App;
