

export default function MenuBar(){
    return(
        <div className='menuBar'>
        <div className='menuLeft'>
            <div className='btnStyle'>Predictive Maintenance</div>
           
        </div>
        <div>
            
        </div>
      </div>
    )
}